import React from "react";
import whatsapp from "../assets/img/whatsapp.svg";
import "../assets/css/whatsapp.css";

export const WhatsApp = () => {
  return (
    <div className="whatsapp">
      {window.screen.width < 900 ? (
        <a
          href="https://wa.me/5492615911010?text=Hola! Me gustaría hacer una consulta"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp} alt="whatsapp" />
        </a>
      ) : (
        <a
          href="http://web.whatsapp.com/send?phone=5492615911010&text=Hola! Me gustaría hacer una consulta"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp} alt="whatsapp" />
        </a>
      )}
    </div>
  );
};
