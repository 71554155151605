import React from 'react'
import platform1Mobile from '../assets/img/platform1Mobile.png'
import platform2Mobile from '../assets/img/platform2Mobile.png'
import platform3Mobile from '../assets/img/platform3Mobile.png'
import platform4Mobile from '../assets/img/platform4Mobile.png'
import platform1 from '../assets/img/platform1.png'
import platform2 from '../assets/img/platform2.png'
import platform3 from '../assets/img/platform3.png'
import platform4 from '../assets/img/platform4.png'
import arrow from "../assets/img/arrow1.svg"
import '../assets/css/ourPlatforms.css'
import { FormattedMessage } from "react-intl";

const OurPlatforms = () => {
  return (
    <div id='how-we-help' className='our-platform'>
        <h1 className='our-platform-title'>
          <FormattedMessage id="ownTechnology.textH1" defaultMessage="Our platform:" />
        </h1>
        <div className='our-platform-container'>
          <img src={window.innerWidth >= 1001 ? platform1 : platform1Mobile} alt='Platform 1' className={window.innerWidth >= 1001 ? 'img-platform-dashboard' : 'img-platform-mobile'}/>
          <div>
            <p className={window.innerWidth >= 1001 ? 'our-platform-content-dashboard' : 'our-platform-content'}>
              <FormattedMessage id="ownTechnology.textP1" defaultMessage="The CRM information is reflected in the dashboards. In them, you will be able to observe: sales results, filters by date, salesperson, and lead source. Classification by data status. Conversion ratios." />
            </p>
            {window.innerWidth >= 1001 ? (<img src={arrow} alt='Arrow' className='our-platform-arrow-dashboard'/>):(<></>)}
          </div>
        </div>
        <div className='our-platform-container-reverse'>
          <img src={window.innerWidth >= 1001 ? platform2 : platform2Mobile} alt='Platform 2' className={window.innerWidth >= 1001 ? 'img-platform-masterapp' : 'img-platform-mobile'}/>
          <div>
            {window.innerWidth >= 1001 ? (<>
              <h1 className='our-platform-number-title'>2.</h1>
              <h2 className='our-platform-platform-title'>APP MASTER DEALER</h2>
            </>):(<></>)}
            <p className={window.innerWidth >= 1001 ? 'our-platform-content-masterapp' : 'our-platform-content'}>
              <FormattedMessage id="ownTechnology.textP2" defaultMessage="Implementation of a CRM application for the salesperson to use easily and intuitively.The application is easily accessible and allows for selling anywhere and anytime." />
            </p>
          </div>
          {window.innerWidth >= 1001 ? (<img src={arrow} alt='Arrow' className='our-platform-arrow-masterapp'/>):(<></>)}
        </div>
        <div className='our-platform-container'>
          <img src={window.innerWidth >= 1001 ? platform3 : platform3Mobile} alt='Platform 3' className={window.innerWidth >= 1001 ? 'img-platform-assigner' : 'img-platform-mobile'}/>
          <div>
            {window.innerWidth >= 1001 ? (<>
              <h1 className='our-platform-number-title-assigner'>3.</h1>
              <h2 className='our-platform-platform-assigner-title'>ASSIGNER</h2>
            </>):(<></>)}
            <p className={window.innerWidth >= 1001 ? 'our-platform-content-assigner' : 'our-platform-content'}>
              <FormattedMessage id="ownTechnology.textP3" defaultMessage="It allows assigning leads to salespeople based on the source of origin, day, and schedule, enabling efficient utilization of leads while considering conversion rates by salesperson and source of origin. It ensures efficiency in lead handling and coverage." />
            </p>
            {window.innerWidth >= 1001 ? (<img src={arrow} alt='Arrow' className='our-platform-arrow-assigner'/>):(<></>)}
          </div>
        </div>
        <div className='our-platform-container-reverse'>
          <img src={window.innerWidth >= 1001 ? platform4 : platform4Mobile} alt='Platform 4' className={window.innerWidth >= 1001 ? 'img-platform-geopanel' : 'img-platform-mobile'}/>
          <div>
            {window.innerWidth >= 1001 ? (<>
              <h1 className='our-platform-number-title-geopanel'>4.</h1>
              <h2 className='our-platform-platform-geopanel-title'>GEO PANEL</h2>
            </>):(<></>)}
            <p className={window.innerWidth >= 1001 ? 'our-platform-content-geopanel' : 'our-platform-content'}>
              <FormattedMessage id="ownTechnology.textP4" defaultMessage="Coverage checker based on the coordinates provided by the potential customer." />
            </p>
          </div>
        </div>
    </div>
  )
}

export default OurPlatforms