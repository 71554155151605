import React from 'react'
import "../assets/css/privacyPolicies.css";

import { Logo } from "./Logo";

const PrivacyPolicies = () => {
  return (
    <div className="fullpage-topbar">
        <div className="top-bar">
            <Logo />
          </div>
        <div className='container-topbar'>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1 className='policies-title'>
            Privacidad
            </h1>
            <br/>
            <h2>
            Políticas de Privacidad y Protección de datos personales de Master Dealer SA - APP
            </h2>  
            <br/>
            <h3>1. Disposiciones Generales.</h3>
            <p>
            De conformidad con la Ley 25.326 de Protección de Datos Personales, el Decreto Reglamentario 1558/2001, las Disposiciones de la Dirección Nacional de Protección de Datos Personales (DNPDP) y a la libre circulación de estos datos, Master Dealer SA, CUIT: 30-70990005-2 (en adelante, el Responsable), informa a los usuarios de la aplicación CRM Móvil (en adelante, la Aplicación), acerca del tratamiento de los datos personales, que ellos voluntariamente hayan facilitado durante el proceso de registro, acceso y utilización del servicio.
            </p>
            <br/>
            <h3>2. Finalidad del tratamiento de datos. </h3>
            <p>
            Para proceder al registro, acceso y posterior uso de la Aplicación, el Usuario deberá facilitar -de forma voluntaria-, datos de carácter personal (esencialmente, identificativos y de contacto), los cuales serán incorporados a soportes automatizados titularidad de Master Dealer SA.
            </p>
            <p>
            La recogida, almacenamiento, modificación, estructuración y en su caso, eliminación, de los datos proporcionados por los Usuarios, constituirán operaciones de tratamiento llevadas a cabo por el Responsable, con la finalidad de garantizar el correcto funcionamiento de la Aplicación, mantener la relación de prestación de servicios y/o comercial con el Usuario, y para la gestión, administración, información, prestación y mejora del servicio.
            </p>
            <p>
            Los datos personales facilitados por el Usuario -especialmente, el correo electrónico o e-mail- podrán emplearse también para remitir boletines (newsletters), así como comunicaciones comerciales de promociones y/o publicidad de la Aplicación, siempre y cuando, el Usuario haya prestado previamente su consentimiento expreso para la recepción de estas comunicaciones vía electrónica.
            </p>
            <br/>
            <h3>3. Legitimación.</h3>
            <p>
            El tratamiento de los datos del Usuario, se realiza con las siguientes bases jurídicas que legitiman el mismo:
            </p>
            <ul>
              <li>La solicitud de información y/o la contratación de los servicios de la Aplicación, cuyos términos y condiciones se pondrán a disposición del Usuario en todo caso, con carácter previo, para su expresa aceptación.</li>
              <li>El consentimiento libre, específico, informado e inequívoco del Usuario, poniendo a su disposición la presente política de privacidad, que deberá aceptar mediante una declaración o una clara acción afirmativa, como el marcado de una casilla dispuesta al efecto.</li>
            </ul>
            <p>
            En caso de que el Usuario no facilite a Master Dealer SA sus datos, o lo haga de forma errónea o incompleta, no será posible proceder al uso de la Aplicación.
            </p>
            <br/>
            <h3>4. Conservación de los datos personales.</h3>
            <p>
            Los datos personales proporcionados por el Usuario, se conservarán en los sistemas y bases de datos del Responsable del Tratamiento, mientras aquél continúe haciendo uso de la Aplicación, y siempre que no solicite su supresión.
            </p>
            <p>
            Con el objetivo de depurar las posibles responsabilidades derivadas del tratamiento, los datos se conservarán por un período mínimo de cinco años.
            </p>
            <br/>
            <h3>5. Destinatarios.</h3>
            <p>
            Los datos no se comunicarán a ningún tercero ajeno a Master Dealer SA, salvo obligación legal o en cualquier caso, previa solicitud del consentimiento del Usuario.
            </p>
            <p>
            De otra parte, Master Dealer SA podrá dar acceso o transmitir los datos personales facilitados por el Usuario, a terceros proveedores de servicios, con los que haya suscrito acuerdos de encargo de tratamiento de datos, y que únicamente accedan a dicha información para prestar un servicio en favor y por cuenta del Responsable.
            </p>
            <br/>
            <h3>6. Retención de datos.</h3>
            <p>
            Master Dealer SA, informa al Usuario de que, como prestador de servicio de alojamiento de datos y en virtud de lo establecido en la Ley 25.326 de Protección de Datos Personales, retiene por un período máximo de 12 meses la información imprescindible para identificar el origen de los datos alojados y el momento en que se inició la prestación del servicio.
            </p>
            <br/>
            <h3>7. Registro de la Base de Datos.</h3>
            <p>
            Los datos recabados por el sitio web son incorporados a una base de datos de la cual masterdealer.co es responsable. La misma se encuentra registrada en la Dirección Nacional de Protección de Datos Personales del Ministerio de Justicia y Derechos Humanos de la Nación (República Argentina) bajo el registro N° 71.340, en cumplimiento de lo dispuesto en el art. 3 de la Ley 25.326. La Dirección Nacional de Protección de Datos Personales, Órgano de Control de la Ley Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales. El sitio web para consultas sobre responsables inscriptos es www.jus.gob.ar/datospersonales.
            </p>
            <p>
              La retención de estos datos no afecta al secreto de las comunicaciones y sólo podrán ser utilizados en el marco de una investigación criminal o para la salvaguardia de la seguridad pública, poniéndose a disposición de los jueces y/o tribunales o del Ministerio que así los requiera.
            </p>
            <p>
            La comunicación de datos a las Fuerzas y Cuerpos de Seguridad del Estado, se hará en virtud de lo dispuesto por la normativa sobre protección de datos personales, y bajo el máximo respeto a la misma.
            </p>
            <br/>
            <h3>8. Protección de la información alojada.</h3>
            <p>
            El Responsable del Tratamiento, adopta las medidas necesarias para garantizar la seguridad, integridad y confidencialidad de los datos conforme a lo dispuesto en Ley 25.326 de Protección de Datos Personales, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de los mismos.
            </p>
            <p>
            Si bien el Responsable, realiza copias de seguridad de los contenidos alojados en sus servidores, sin embargo no se responsabiliza de la pérdida o el borrado accidental de los datos por parte de los Usuarios. De igual manera, no garantiza la reposición total de los datos borrados por los Usuarios, ya que los citados datos podrían haber sido suprimidos y/o modificados durante el periodo de tiempo transcurrido desde la última copia de seguridad.
            </p>
            <br/>
            <h3>9. Ejercicio de los derechos.</h3>
            <p>
            Master Dealer SA, informa al Usuario de que le asisten los derechos de acceso, rectificación, limitación, supresión, oposición y portabilidad, los cuales podrá ejercitar mediante petición dirigida al correo electrónico: contacto@masterdealer.co
            </p>
            <p>
            Asimismo, el Usuario tiene derecho a revocar el consentimiento inicialmente prestado, y a interponer reclamaciones de derechos frente a la Agencia de Acceso a la Información Pública.
            </p>
            <br/>
            <h3>10. Comunicaciones comerciales por vía electrónica.</h3>
            <p>
            Master Dealer SA no enviará comunicaciones publicitarias o promocionales por correo electrónico u otro medio de comunicación electrónica equivalente que previamente no hubieran sido solicitadas o expresamente autorizadas por los destinatarios de las mismas.
            </p>
            <p>
            En el caso de usuarios con los que exista una relación contractual, jurídica o de servicios previa, el Responsable del Tratamiento, sí está autorizado al envío de comunicaciones comerciales referentes a productos o servicios del Responsable que sean similares a los que inicialmente fueron objeto de contratación con el cliente.
            </p>
            <p>
            En caso de que el Usuario quiera darse de baja a la hora de recibir las citadas comunicaciones, podrá hacerlo remitiendo su voluntad por e-mail al correo electrónico: contacto@masterdealer.co.
            </p>
            <br/>
            <h3>11. Información Recopilada.</h3>
            <p>
            La aplicación "Master App" puede recopilar y procesar la siguiente información:
            </p>
            <ul>
              <li>Información de ubicación: La aplicación puede acceder a su ubicación en segundo plano para proporcionarle servicios basados en la misma. Esta información se utiliza para proporcionar métricas de ubicación del usuario para informar sobre el desempeño del usuario. En caso de que el Usuario no comparta su ubicación, no se proveerá ningún dato.</li>
            </ul>
            <br/>
        </div>
    </div>
  )
}

export default PrivacyPolicies