import React from 'react'
import adtLogo from '../assets/img/adtlogo.svg'
import brinksLogo from '../assets/img/brinksLogo.svg'
import directvLogo from '../assets/img/directvLogo.svg'
import electiumLogo from '../assets/img/electiumLogo.svg'
import movistarLogo from '../assets/img/movistarLogo.svg'
import sunpowerLogo from '../assets/img/sunpowerLogo.svg'
import celerLogo from '../assets/img/celerLogo.svg'
import perfectaLogo from '../assets/img/PerfectaEnergiaLogo.svg'
import "../assets/css/sucessStories.css"
import { FormattedMessage } from "react-intl";

const SucessStories = () => {
  return (
    <div className='succes-stories' id='success-stories'>
        <h1 className='succes-stories-title'>
          <FormattedMessage id="succesStories.textH1" defaultMessage="Success stories:" />
        </h1>
        {window.innerWidth >= 1001 ? (
          <>
            <div className='success-stories-images-position'>
            <img src={adtLogo} alt='ADT Logo ' className='success-stories-images-adt'/>
            <img src={sunpowerLogo} alt='Sunpower Logo' className='success-stories-images'/>
            <img src={movistarLogo} alt='Movistar Logo' className='success-stories-images'/>
            <img src={electiumLogo} alt='Electium Logo' className='success-stories-images'/>
          </div>
          <div className='success-stories-images-position'>
            <img src={brinksLogo} alt='Brinks Logo' className='success-stories-images-brinks'/>
            <img src={directvLogo} alt='Directv Logo' className='success-stories-images-2'/>
            <img src={celerLogo} alt='Celer Logo' className='success-stories-images-2'/>
            <img src={perfectaLogo} alt='Perfecta Energia Logo' className='success-stories-images-2'/>
          </div>
          </>
        ):(
          <>
            <div className='success-stories-images-position'>
              <img src={adtLogo} alt='ADT Logo ' className='success-stories-images-adt'/>
              <img src={sunpowerLogo} alt='Sunpower Logo' className='success-stories-images-sunpower'/>
            </div>
            <div className='success-stories-images-position'>
                <img src={movistarLogo} alt='Movistar Logo' className='success-stories-images'/>
                <img src={electiumLogo} alt='Electium Logo' className='success-stories-images'/>
            </div>
            <div className='success-stories-images-position'>
                <img src={brinksLogo} alt='Brinks Logo' className='success-stories-images'/>
                <img src={directvLogo} alt='Directv Logo' className='success-stories-images'/>
            </div>
          </>
        )}
    </div>
  )
}

export default SucessStories