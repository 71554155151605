import React from "react";
import { FormattedMessage } from "react-intl";
import "../assets/css/footer.css";

export const Footer = (data) => {
  const phone = data.data.phoneNumber
  const numberIcon = data.data.phoneNumberIcon
  return (
    <div className="footer">
      {/* <img src={master} alt="master" /> */}
      <div className="footer-country">
        <div className="box-country">
          <h5>Argentina</h5>
          <p>Patricias Mendocinas<br/>617 - Piso 9 y 10 -<br/> Mendoza, Argentina</p>
        </div>
        {window.innerWidth >= 1001 ? (<div className="separator-country">|</div>):(<></>)}
        <div className="box-country">
          <h5>
            <FormattedMessage id="footer.USA" defaultMessage="USA" />
          </h5>
          <p>
            6430 Sunset Corporate <br /> Dr. City: Las Vegas, NV
          </p>
        </div>
        {window.innerWidth >= 1001 ? (<div className="separator-country">|</div>):(<></>)}
        <div className="box-country">
          <h5>Chile</h5>
          <p>
          Diego de Velazquez 2071 - Dpto. 907 - Providencia, Región Metropolitana, Chile
            </p>
        </div>
        {window.innerWidth >= 1001 ? (<div className="separator-country">|</div>):(<></>)}
        <div className="box-country">
          <h5>Uruguay</h5>
          <p>Juan D. Jackson 1381 bis, 11200 Montevideo,<br/>Dpto.de Montevideo, Uruguay</p>
        </div>
        {window.innerWidth >= 1001 ? (<div className="separator-country">|</div>):(<></>)}
        <div className="box-country">
          <h5>
            <FormattedMessage id="footer.Spain" defaultMessage="Spain" />
          </h5>
          <p>
            CL Benet Mateu Nº40 - 08034 <br /> Barcelona,{" "}
            <FormattedMessage id="footer.Spain" defaultMessage="Spain" />
          </p>
        </div>
      </div>
      <hr className="short-hr" />
      <div className="footer-info">
        <div className="item">
          <a href={`tel:${phone}`}>
            <span>{numberIcon}</span>
          </a>
        </div>
        <div className="separator">|</div>
        <div className="item">
          <a
            href={`mailto:marketing@masterdealer.co?subject=Contacto&body=Hola%20Master%20Dealer`}
          >
            <span>contacto@masterdealer.co</span>
          </a>
        </div>
      </div>
    </div>
  );
};
