import React, { useState } from "react";
import logo from "../assets/img/MasterDealerLogo.png";
import menuOpenButton from "../assets/img/hamburgerMenu.svg";
import menuCloseButton from "../assets/img/hamburgerCloseMenu.svg";
import phone from "../assets/img/phone.svg";
import phoneDesktop from "../assets/img/phoneDesktop.svg";
import "../assets/css/topBar.css";
import { FormattedMessage } from "react-intl";

export const TopBar = (data) => {
  const [open, isOpen] = useState(false);

  const links = window.innerWidth >= 1001 ? "desktop-links" : "mobile-links";

  const mobileButton =
    window.innerWidth >= 1001 ? "mobile-no-display" : "mobile-display";

  const toggleMenu = () => {
    isOpen(!open);
  };

  const hr = window.innerWidth >= 1001 ? "" : <hr />;

  const menu = open || window.innerWidth >= 1001 ? "show-menu" : "hide-menu";

  const phoneNumber = data.data.phoneNumber

  return (
    <div className="top-bar">
      <div className="img-container-top-bar">
        <a href="#root">
          <img src={logo} alt="logo" />
        </a>
      </div>

      <a href={`tel:${phoneNumber}`} className="floating-button">
        <div className="phone-button">
          <img
            src={window.innerWidth >= 1001 ? phoneDesktop : phone}
            alt="phone"
          />
          <div className="call-text">
            <FormattedMessage id="topBar.call" defaultMessage="Call" />
          </div>
        </div>
      </a>

      <div className={menu}>
        <div className={links}>
          <a href="#what-we-fix" onClick={() => isOpen(!open)}>
            <FormattedMessage id="topBar.Link1" defaultMessage="Solutions" />
          </a>
          {hr}
          <a href="#how-we-help" onClick={() => isOpen(!open)}>
            <FormattedMessage id="topBar.Link2" defaultMessage="Products" />
          </a>
          {hr}
          <a href="#success-stories" onClick={() => isOpen(!open)}>
            <FormattedMessage id="topBar.Link3" defaultMessage="Success Stories" />
          </a>
          {hr}
          <a href="#contact-us" onClick={() => isOpen(!open)}>
            <FormattedMessage id="topBar.Link4" defaultMessage="Contact" />
          </a>
          {hr}
        </div>
      </div>

      <div className={mobileButton}>
        {open ? (
          <img
            src={menuCloseButton}
            alt="menu"
            className="menu-button"
            onClick={() => toggleMenu()}
          />
        ) : (
          <img
            src={menuOpenButton}
            alt="menu"
            className="menu-button"
            onClick={() => toggleMenu()}
          />
        )}
      </div>
    </div>
  );
};
