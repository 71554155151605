import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { langContext } from "../context/LangContext";
import { useForm } from "../hooks/useForm";
import "../assets/css/contactUs.css";
import { useState } from "react";
import axios from "axios";
import checkGreen from "../assets/img/circleCheckGreen.svg";
import { FormMessage } from "./FormMessage";
import { Modal } from './Modal';
import { useModal } from '../hooks/useModal';
import { ModalBody, ModalHeader } from 'reactstrap';
import errorSymbol from '../assets/img/errorFormMessage.svg'

export const ContactUs = () => {
  const context = useContext(langContext);
  const locale = context.locale;
  const initialForm = {
    fullname: "",
    email: "",
    message: "",
    phone: "",
  };

  const [formValues, handleInputChange, reset] = useForm(initialForm);
  const [correctValidation, setCorrectValidation] = useState(0);
  const date = new Date ();
  const [year, month, day] = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
  const [hour, minutes] = [date.getHours(), date.getMinutes()];
  const [isOpenModal, openModal, closeModal] = useModal(false);
  const [loading, setLoading] = useState(false);

  // Tomo valores de la query
  const utms = window.location.search.substring(1).split("&");
  const utm_source = utms[0] ? utms[0].split("=").pop() : "Organic";
  const utm_medium = utms[1] ? utms[1].split("=").pop() : "Organic";
  const utm_campaign = utms[2] ? utms[2].split("=").pop() : "Organic";
  const utm_term = utms[3] ? utms[3].split("=").pop() : "Organic";
  const utm_content = utms[4] ? utms[4].split("=").pop() : "Organic";
  // Expresion regular para validar el email
  const validar_email = (email) => {
    if (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)){
      return true
    } else {
      return false
    }
  }

  const validationsForm = async (form) => {
    if (!form.fullname.trim()) {
      setCorrectValidation(5);
      setLoading(false);
      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      return false
    }
    if (form.phone.trim()) {
      if (form.phone.length > 8 && form.phone.length < 13){
        setCorrectValidation(0);
      }else{
        setCorrectValidation(2);
        setLoading(false);
        setTimeout(() => {
          setCorrectValidation(0);
        }, 4000);
        return false
      }
    }else{
      setCorrectValidation(2);
      setLoading(false);
        setTimeout(() => {
          setCorrectValidation(0);
        }, 4000);
        return false
    }
    return true
};

  const handleSubmit = async (e) => {
    e.preventDefault(); // Evito que se recargue la pagina
    setLoading(true);
    const validation = validationsForm(formValues)
    // Verifico si el email es valido.
    const email_validado = validar_email(formValues.email)
    if (email_validado !== true){
      setCorrectValidation(3);
      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      return false
    }
    let lead = {
      email: formValues.email,
      status: "New",
      phone_fax: formValues.email,
      first_name: formValues.fullname,
      phone_home: formValues.phone,
      utm_term_c: utm_term,
      description: formValues.message,
      lead_source: utm_source,
      date_entered: year + "-" + month + "-" + day + " " + hour + ":" + minutes,
      phone_mobile: formValues.phone,
      utm_medium_c: utm_medium,    
      utm_source_c: utm_source,
      utm_content_c: utm_content,
      utm_campaign_c: utm_campaign,
      assigned_user_id: "2e659e02-cd7c-39d2-ed26-5fd8f5c9efbc",
      identification_c: "-",
      primary_address_state: "-"
    };

    // Si todo esta correcto, lo envio
    if (
      validation && email_validado
    )
    {
      await axios({
        method: "POST",
        url: "processor.php",
        headers: {
          "Content-Type": "application/json",
        },
        data: lead,
      })
        .then((res) => {
          if (res.data.includes("success")) {
            openModal()
            setLoading(false);
            setTimeout(function(){
              closeModal();
            },5000);            
          }
        })
        .catch((err) => {
          setCorrectValidation(4); // "A ocurrido un error inesperado"
          setLoading(false);
          setTimeout(() => {
            setCorrectValidation(0);
          }, 4000);
        });

      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      reset();
      return;
    }
  };

  return (
    <div className="contact-us" id="contact-us">
      <h2 className="contact-us-title">
        <FormattedMessage id="contactUs.H2" defaultMessage={"Do you need clients?"} />
      </h2>
      <p className="contact-us-subtitle">
        <FormattedMessage
          id="contactUs.textP"
          defaultMessage={"We'll help you get them!"}
        />
      </p>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="fullname"
          id="fullname"
          value={formValues.fullname}
          onChange={handleInputChange}
          placeholder={locale === "es-ES" ? "Nombre y Apellido *" : "Name and last name *"}
          required
        />
        <input
          type="email"
          name="email"
          id="email"
          value={formValues.email}
          onChange={handleInputChange}
          placeholder="Email *"
          required
        />    
        <input
          name="phone"
          id="phone"
          type="number"
          value={formValues.phone}
          onChange={handleInputChange}
          placeholder={locale === "es-ES" ? "Teléfono *" : "Phone *"}
          required
        />
        <textarea
          name="message"
          id="message"
          value={formValues.message}
          onChange={handleInputChange}
          placeholder={locale === "es-ES" ? "Contanos que buscas" : "Tell us what you are looking for."}
        />
        <br />
        <div className="checkbox-container">
          {loading ? (
            <button type="submit" disabled id="contactUs.button">
              <span
                className="lds-ring-loader"
                role="status"
                aria-hidden="true"
              ></span>
              {"      "}
              {locale === "es-ES" ? "Enviando" : "Sending"}
            </button>
          ) : (
            <button type='Submit' id="contactUs.button">
              {locale === "es-ES" ? "Enviar consulta" : "Submit your query"}
            </button>
          )}
          <br/>
          {locale === "es-ES" ? 
            <div className="message-es">
              {correctValidation !== 0 ?
                <img src={errorSymbol} alt="Error symbol" className="error-symbol"/> 
              : <></>}
              <FormMessage correctValidation={correctValidation} />
            </div>  :
            <div className="message-en">
              {correctValidation !== 0 ?
                <img src={errorSymbol} alt="Error symbol" className="error-symbol"/>
              : <></>}
              <FormMessage correctValidation={correctValidation} />
            </div>
          }
        </div>
        <div>
          <Modal isOpen={isOpenModal} closeModal={closeModal}>
              <ModalHeader style={{display: 'block'}}>
                  <span style={{float: 'right'}}></span>
              </ModalHeader>
              <ModalBody>
                <div className="checkGreen">
                  <img src={checkGreen} className="checkGreen-container" alt="checkGreen"/>
                </div>
                {locale === "es-ES" ? 
                  <h1 className="title-es">
                    <FormattedMessage
                      id="thanks.Page.H1"
                      defaultMessage={"Thank you for getting in touch!"}
                    />
                  </h1>
                : 
                <h1 className="title-en">
                  <FormattedMessage
                    id="thanks.Page.H1"
                    defaultMessage={"Thank you for getting in touch!"}
                  />
                </h1>}
              <h2 className='sub-title'>
                <FormattedMessage
                  id="thanks.Page.H2"
                  defaultMessage={"We will contact you soon."}
                />
              </h2>
              </ModalBody>
          </Modal>
        </div>
      </form>
    </div>
  );
};
