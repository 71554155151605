import React, { useEffect, useState } from "react";
import EnglishText from "../lang/en-US.json";
import SpanishText from "../lang/es-ES.json";
import { IntlProvider } from "react-intl";

const langContext = React.createContext();

const LangProvider = ({ children }) => {
  const [locale, setLocale] = useState("en-US");
  const [texts, setTexts] = useState("EnglishText");

  const defineLang = (language) => {
    if (language !== "es-ES") {
      setTexts(EnglishText);
      setLocale("en-US");
    } else {
      setTexts(SpanishText);
      setLocale("es-ES");
    }
  };
  useEffect(() => {
    if (window.location.pathname === "/") {
      window.location.pathname = "/en";
    }
    if (window.location.pathname === "/en") {
      defineLang("en-US");
    }
    if (window.location.pathname === "/es") {
      defineLang("es-ES");
    }
  }, []);

  return (
    <langContext.Provider value={{ defineLang: defineLang, locale: locale }}>
      <IntlProvider locale={locale} messages={texts}>
        {children}
      </IntlProvider>
    </langContext.Provider>
  );
};

export { LangProvider, langContext };
