import React, { useContext } from "react";
import { langContext } from "../context/LangContext";
import MasterWorldMobile from '../assets/img/masterWorldMobile.png'
import '../assets/css/salesHappen.css'
import { FormattedMessage } from "react-intl";

const SalesHappen = () => {
  const context = useContext(langContext);
  const locale = context.locale;
  return (
    <div className='where-sales-happen'>
        <img src={MasterWorldMobile} alt='Master Dealer World' className='masterdealer-world'/>
        <br/><br/>
        <div className='sales-happen-content'>
          <h2 className={locale === "es-ES" ? 'sales-happen-title-es' : 'sales-happen-title-en'}>
            <FormattedMessage id="salesHappen.textH2-title1" defaultMessage="Where" />
          </h2>
          <h2 className={locale === "es-ES" ? 'sales-happen-title-es' : 'sales-happen-title-en'}>
            <FormattedMessage id="salesHappen.textH2-title2" defaultMessage="sales happen" />
          </h2>
          <h1 className={locale === "es-ES" ? 'sales-happen-subtitle-es' : 'sales-happen-subtitle-en'}>
            <FormattedMessage id="salesHappen.textH1-subtitle" defaultMessage="WE MAKE COMPANIES JOIN THEIR LEADS" />
          </h1>
          <p className='sales-happen-p'>
            <FormattedMessage id="salesHappen.textP" defaultMessage="We capture high-quality leads so that you can turn them into sales. We provide our platform for easy and intuitive sales management." />
          </p>
        </div>
    </div>
  )
}

export default SalesHappen