import React, { useContext } from "react";
import { langContext } from "../context/LangContext";
import "../assets/css/formMessage.css";

export const FormMessage = ({ correctValidation }) => {
  const context = useContext(langContext);
  const locale = context.locale;
  return (
    <div>
      {correctValidation === 2 ? (
        <div
          className="telefono"
          role="alert"
        >
          {locale === "es-ES" ? "Numero de teléfono no válido!" : "Invalid phone number!"}
          <br/>
          <i className="glyphicon glyphicon-thumbs-up"></i>
        </div>
      ) : correctValidation === 3 ? (
        <div
          className="mail"
          role="alert"
        >
          {locale === "es-ES" ? "Email no válido!" : "Invalid email!"}<br />
          <i className="glyphicon glyphicon-thumbs-up"></i>
        </div>
      ) : correctValidation === 4 ? (
        <div
          className="error-post"
          role="alert"
        >
          {locale === "es-ES" ? "A ocurrido un error inesperado." : "An unexpected error has occurred."}<br /><br />
          <i className="glyphicon glyphicon-thumbs-up"></i>
        </div>
      ) : correctValidation === 5 ? (
        <div
          className="name"
          role="alert"
        >
          {locale === "es-ES" ? "Nombre incompleto!" : "Invalid Name!"}<br /><br />
          <i className="glyphicon glyphicon-thumbs-up"></i>
        </div>
      ) : null}
    </div>
  );
};
