import React, { useEffect, useState } from 'react'
import PrivacyPolicies from "./components/PrivacyPolicies";
import PrivacyPoliciesApp from "./components/PrivacyPoliciesApp";
import { TopBar } from "./components/TopBar";
import "./assets/css/App.css";
import "@fontsource/urbanist";
import "@fontsource/bebas-neue";
import "@fontsource/montserrat";
import SalesHappen from './components/SalesHappen';
import HowWeDo from './components/HowWeDo';
import OurPlatforms from './components/OurPlatforms';
import SucessStories from './components/SucessStories';
import { ContactUs } from './components/ContactUs';
import { Footer } from './components/Footer';
import { WhatsApp } from "./components/WhatsApp"


const App = () => {

  const [data, setData] = useState(null);

  const fetchData = async () =>{
    const data = await fetch('landing-config.json')
    return data.json()
  }

  const [offset, setOffset] = useState(0);
  // const url = window.location.pathname;
  useEffect(() => {
    fetchData()
      .then((response) => {
        setData(response); // Store the resolved value in state
      })
      .catch((error) => {
        console.error(error);
      });
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  const url = window.location.pathname;

  return (
    <>
      {url === "/privacypolicies" ? (
        <PrivacyPolicies/>
      ): url === "/pr353n74c10n1" ? (
        <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQFQFBWzL6_nIRJxxUi8-MARegQKMd0uCkab0GsNwzUPOUctdJAbjuUQm_nZs6xepLMouhrzu_DTO40/embed?start=false&loop=false&delayms=60000" title="Pitch one of Master Dealer" frameborder="0" width="100%" height="720" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"/>
      ): url === "/pr353n74c10n2" ? (
        <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTa0r-d2BiTua9R5V7Dcp9OWQMK-CWlOejWosJGR-2uHQVZH8a7VK-pePaRuUoKPxbl0tQn2bmrUMvn/embed?start=false&loop=false&delayms=60000" title="Pitch two of Master Dealer" frameborder="0" width="100%" height="720" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"/>
      ): url === "/privacypoliciesapp" ? (
        <PrivacyPoliciesApp/>
      ):(
        <>
          {data !== null ? (
            <>
              {offset <= 30 ? (
                  <div className="top-bar-position">
                    <TopBar data={data}/>
                  </div>
                ) : (
                  <div className="top-bar-position-white">
                    <TopBar data={data}/>
                  </div>
              )}
              <SalesHappen/>
              <HowWeDo/>
              <OurPlatforms/>
              <SucessStories/>
              <ContactUs/>
              <WhatsApp />
              <Footer data={data}/>
            </>
          ):(<></>)}
        </>
      )}
    
    </>
  )
}

export default App