import React from 'react'
import '../assets/css/howWeDo.css'
import { FormattedMessage } from "react-intl";
import googleLogo from "../assets/img/GoogleLogo.svg"
import metaLogo from "../assets/img/MetaLogo.svg"
import tiktokLogo from "../assets/img/TiktokLogo.svg"

const HowWeDo = () => {
  return (
    <div id='what-we-fix' className='how-we-do' >
        <h1 className='how-we-do-it-title'>
          <FormattedMessage id="howWeDo.textH1" defaultMessage="How we do it?" />
        </h1>
        <div className='how-we-do-it-content'>
          <p className='how-we-do-it-p'>
            <FormattedMessage id="howWeDo.textP1" defaultMessage="We integrate the advertising platforms of the Meta universe, Google, and TikTok into our system." />
          </p>
          <p className='how-we-do-it-p'>
            <FormattedMessage id="howWeDo.textP2" defaultMessage="This information is reflected in the dashboards so that our clients can observe in real-time the ongoing sales processes." />
          </p>
          <p className='how-we-do-it-p'>
            <FormattedMessage id="howWeDo.textP3" defaultMessage="We work collaboratively with our clients, encouraging constant feedback to enhance our efforts and pursue continuous improvement." />
          </p>
          {window.innerWidth >= 1001 ? <div className='logos-position'>
            <img src={googleLogo} alt='Google-logo' className='logo-google'/>
            <img src={metaLogo} alt='Meta-logo' className='logo-meta'/>
            <img src={tiktokLogo} alt='Tiktok-logo' className='logo-tiktok'/>
          </div> : <></>}
        </div>
    </div>
  )
}

export default HowWeDo